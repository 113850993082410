<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Role Links</v-card-title>

            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Link Name</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="defaultItem.role_Link" outlined dense clearable
                                    required></v-text-field>
                                <label class="add-text">Link Display Name</label>
                                <v-text-field class="text" v-model="defaultItem.link_display_name" outlined dense
                                    :rules="[defaultItem.link_display_name.required]" clearable required></v-text-field>
                                <label class="add-text">Action Name</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="defaultItem.actionName" outlined dense
                                    :rules="[defaultItem.actionName.required]" clearable required></v-text-field>

                                <label class="add-text">Sort Order</label><span style="color: red;"> *</span>
                                <v-text-field dense outlined v-model="defaultItem.roleLinkSortOrder"
                                    item-text="roleLink_list_id" item-value="id" type="number" persistent-hint :min="0"
                                    :error="errorMsg.roleLinkSortOrder ? true : false"
                                    :error-messages="errorMsg.roleLinkSortOrder ? 'Required' : ''"
                                    :rules="[v => !!v || 'required']"></v-text-field>
                                <label class="add-text">Role</label><span style="color: red;"> *</span>
                                <v-autocomplete :items="role_list" outlined dense item-value="id" item-text="name"
                                    v-model="defaultItem.roleId" :error="errorMsg.roleId ? true : false"
                                    :error-messages="errorMsg.roleId ? 'Required' : ''"
                                    :rules="[v => !!v || 'required']"></v-autocomplete>


                                <label class="add-text">Role Link Icon</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="defaultItem.roleLinkIcon" outlined dense clearable
                                    persistent-hint hint="Please Enter valid Icon (mdi-view-grid,mdi-file-import..etc)"
                                    required></v-text-field>

                                <v-btn color="success" @click="addRole_link">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>
                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <v-data-table :headers="headers" :items="roleLink_list" class="elevation-1 nowrap" :search="search">

                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line
                                hide-details> </v-text-field>
                            <span style="width: 20px;"></span>
                            <!-- <v-btn class="mb-2 button" @click="addLinkDialoge">Add Role Link</v-btn> -->

                            <v-dialog v-model="dialog" max-width="500px">
                                <v-card>
                                    <v-form v-model="valid" ref="form">
                                        <v-card-title class="heading">
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Link Name</label><span style="color: red;"> *</span>
                                                        <v-text-field dense outlined v-model="editedItem.role_Link"
                                                            item-text="roleLink_list_id" item-value="id" persistent-hint
                                                            :error="errorMsg.role_Link ? true : false"
                                                            :error-messages="errorMsg.role_Link ? 'Required' : ''"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Link Display Name</label>
                                                        <v-text-field dense outlined v-model="editedItem.link_display_name"
                                                            item-text="roleLink_list_id" item-value="id" persistent-hint
                                                            :error="errorMsg.link_display_name ? true : false"
                                                            :error-messages="errorMsg.link_display_name ? 'Required' : ''"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Action Name</label><span style="color: red;"> *</span>
                                                        <v-text-field dense outlined v-model="editedItem.actionName"
                                                            item-text="roleLink_list_id" item-value="id" persistent-hint
                                                            :error="errorMsg.actionName ? true : false"
                                                            :error-messages="errorMsg.actionName ? 'Required' : ''"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Role Link Sort Order</label><span style="color: red;"> *</span>
                                                        <v-text-field dense outlined v-model="editedItem.roleLinkSortOrder"
                                                            item-text="roleLink_list_id" item-value="id" type="number"
                                                            persistent-hint
                                                            :error="errorMsg.roleLinkSortOrder ? true : false"
                                                            :error-messages="errorMsg.roleLinkSortOrder ? 'Required' : ''"
                                                            :rules="[v => !!v || 'required']"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="6" md="6" class="fields">
                                                        <label>Choose Role</label><span style="color: red;"> *</span>
                                                        <v-autocomplete :items="role_list" outlined dense item-value="id"
                                                            item-text="name" v-model="editedItem.roleId"
                                                            :error="errorMsg.roleId ? true : false"
                                                            :error-messages="errorMsg.roleId ? 'Required' : ''"
                                                            :rules="[v => !!v || 'required']"></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12" class="fields">
                                                        <label>Role Link Icon</label><span style="color: red;"> *</span>
                                                        <v-text-field dense outlined v-model="editedItem.roleLinkIcon"
                                                            item-text="roleLink_list_id" item-value="id" persistent-hint
                                                            hint="Please Enter valid Icon (mdi-view-grid,mdi-file-import..etc)"></v-text-field>
                                                    </v-col>



                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-checkbox dense v-model="editedItem.isactive" color="success"
                                                            label="Active"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn class="button-cancel" text @click="close">Cancel</v-btn>
                                            <v-btn class="button" @click="edit" :disabled="!valid">edit</v-btn>
                                        </v-card-actions>
                                    </v-form>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>

                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                            <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <div>
                            <v-icon @click="editItem(item)">mdi mdi-pencil-box</v-icon>
                        </div>
                    </template>

                    <template v-slot:item.edit="{ item }">
                        <v-switch v-model="item.isactive" @change="activeOn(item)"></v-switch>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="deleteRole_link(item)"> mdi mdi-delete</v-icon>
                    </template>
                </v-data-table>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        valid: false,
        init_loading: false,
        loader: false,
        errorMsg: {
            roleLinkId: false,
        },
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Role Links',
                disabled: true,
                href: '',
            },
        ],
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        org: "",
        dialog: false,
        headers: [
            {
                text: "Sr No",
                align: "left",
                sortable: true,
                value: "sr_no",
            },
            {
                text: "Link Name",
                align: "left",
                sortable: true,
                value: "role_Link",
            },
            {
                text: "Link Display Name",
                align: "left",
                sortable: true,
                value: "link_display_name",
            },
            {
                text: "Action Name",
                align: "left",
                sortable: false,
                value: "actionName",
            },
            {
                text: "Sort Order",
                align: "left",
                sortable: false,
                value: "roleLinkSortOrder",
            },
            {
                text: "Role",
                align: "left",
                sortable: false,
                value: "role",
            },
            {
                text: "Role Link Icon",
                align: "left",
                sortable: true,
                value: "roleLinkIcon",
            },




            {
                text: "Active",
                align: "left",
                sortable: true,
                value: "isactive",
            },
            { text: "Actions", value: "action", sortable: false },
            { text: "Active", value: "edit", sortable: false },
            { text: "Delete", value: "delete" },
        ],
        roleLink_list: [],
        role_list: [],
        editedIndex: -1,

        editedItem: {
            id: "",
            roleLinkName: "",
            link_display_name: "",
            roleLinkIcon: "",
            roleLinkDescription: "",
            roleLinkSortOrder: "",
            role_list: "",
            roleListId: "",
            role_Link: "",
            role: "",
            isactive: false,
            actionName: "",
            controllerName: "",
        },
        defaultItem: {
            id: "",
            roleLinkName: "",

            role_Link: "",
            roleLinkIcon: "",
            roleLinkDescription: "",
            roleLinkSortOrder: "",
            role_list: "",
            link_display_name: "",
            roleListId: "",
            actionName: "",
            role: "",
            isactive: false,

            controllerName: "",
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Role Link" : "Edit Role Link";
        },
    },

    // watch: {
    //     dialog(val) {
    //         val || this.close();
    //     },
    //     overlay(val) {
    //         val = () => {
    //             this.overlay = false;
    //         };
    //     },
    // },

    mounted() {
        this.onLoad();
    },
    methods: {
        deleteRole_link(item) {
            const data = {
                roleId: item.roleLinkId,
            };
            axios
                .post("/admin/deleteRolelink", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Role Link  Deleted Successfully  !!");
                        this.onLoad();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        addRole_link() {
            if (this.defaultItem.role_Link == null || this.defaultItem.role_Link == "") {
                this.showSnackbar("#b71c1c", "Please Enter Role Link Name");
                return;
            }
            if (this.defaultItem.actionName == null || this.defaultItem.actionName == "") {
                this.showSnackbar("#b71c1c", "Please Enter Action Name");
                return;
            }
            if (this.defaultItem.roleLinkSortOrder == null || this.defaultItem.roleLinkSortOrder == "") {
                this.showSnackbar("#b71c1c", "Please Enter Sort Order");
                return;
            }
            if (this.defaultItem.roleId == null || this.defaultItem.roleId == "") {
                this.showSnackbar("#b71c1c", "Please Select Role");
                return;
            }
            if (this.defaultItem.roleLinkIcon == null || this.defaultItem.roleLinkIcon == "") {
                this.showSnackbar("#b71c1c", "Please Enter Role Link Icon");
                return;
            }

            this.loader = true;
            axios
                .post("/Admin/saveAdminAddRoleLink", this.defaultItem)
                .then((res) => {
                    if (res.data.status.code == "SUCCESS") {
                        this.loader = false;
                        this.showSnackbar("#4caf50", "Role Link Added Successfully!!!"); // show snackbar on success
                        this.onLoad();
                    } else if (res.data.status.code == "NA") {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Role Link Already Present!!!"); // show snackbar on error
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
            this.close();
        },
        activeOn(item) {
            console.log(item);
            axios
                .post("/admin/roleLinksIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Role link updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        addLinkDialoge() {
            this.dialog = true
            this.$refs.form.reset()
        },

        editItem(item) {
            this.editedIndex = this.roleLink_list.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
            var name = item.role;
            for (let x in this.role_list) {
                if (this.role_list[x].name == name) {
                    this.editedItem.role = this.role_list[x].name;
                    this.editedItem.roleId = this.role_list[x].id;
                    break;
                }
            }
        },
        onLoad() {
            this.init_loading = true;
            axios
                .post("/Admin/adminAddRoleLink")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.init_loading = false;
                        this.roleLink_list = res.data.roleLink_list;
                        this.role_list = res.data.role_list;
                        this.org = res.data.org;
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    window.console.log(error);
                })
                .finally(() => {
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        edit() {
            if (this.editedIndex > -1) {
                if (this.isValidated()) {
                    this.loader = true;
                    axios
                        .post("/Admin/editAdminAddRoleLink", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                //window.alert(res.data.msg)
                                this.loader = false;
                                Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                                this.showSnackbar("#4caf50", "Role Link Updated Successfully..."); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.loader = false;
                                this.showSnackbar("#b71c1c", "Role Link Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }
        }, //edit()

        isValidated() {
            if (this.editedItem.roleLinkId) {
                return true;
            } else {
                if (!this.editedItem.roleLinkId) {
                    this.errorMsg.roleLinkId = true;
                }
                return false;
            }
        }, // .....end of isValidated()
    },
};
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}
</style>
